import Service from "../Service";
import Vue from 'vue'

const resource = "vaccine/";

export default {
    get(vac, requestID) {
        return Service.post(resource, vac, {
            params: { requestID: requestID },
        });
    },
    save(vac, requestID) {
        console.log('Servicio', vac)
        return Service.post(resource + "save", vac, {
            params: { requestID: requestID },
        });
    },

    pagination(vac, requestID) {
        return Service.post(resource + "pagination", vac, {
            params: { requestID: requestID },
        });
    },

    excel(cmp, requestID) {

        return Service.post(resource + "reportexcel", cmp, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID }
        });
    },

    saveMassive(obj, usr) {
        return Vue.prototype.$http.post(resource + "saveMassive", obj, { params: { requestID: usr } });
    },
};