<template>
  <v-col>
    <s-crud
      title="Vacunas Protección Covid"
      add
      edit
      excel
      height="auto"
      :config="config"
      :filter="filter"
      @save="save($event)"
      @doubleClick="dobleClick"
      ref="crud"
    >
      <template v-slot:filter>
        <v-col>
          <v-row justify="center">
            <v-col lg="3"
              ><s-select-definition
                clearable
                v-model="filter.TypeVaccine"
                :def="1272"
                label="Tipo de Vacuna"
              >
              </s-select-definition
            ></v-col>
          </v-row>
        </v-col>
      </template>
      <template scope="props">
        <v-col>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-select-definition
                :def="1060"
                v-model="props.item.TypePayroll"
                label="Tipo Planilla"
              />
            </v-col>
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-select-worker
                label="Datos de trabajadores"
                :typepayrollallow="props.item.TypePayroll"
                :text="props.item.WkrName"
                v-model="DataWorker"
                returnObject
              ></s-select-worker>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-select-definition
                :def="1272"
                full
                v-model="props.item.TypeVaccine"
                label="Tipo Vacuna"
                add
              />
            </v-col>
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-select-definition
                :def="1273"
                full
                v-model="props.item.TypeDose"
                label="Dosis"
                add
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-date
                label="Fecha de Vacuna"
                v-model="props.item.VacDate"
              ></s-date>
            </v-col>
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-text
                label="Observaciones"
                v-model="props.item.VacDescription"
              />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col align="center">
              <v-chip color="info" small>Datos DNI</v-chip>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-date
                label="Fecha Emision"
                v-model="props.item.VacDateEmitionDni"
              ></s-date>
            </v-col>
            <v-col cols="12" class="s-col-form" md="3" lg="3" sm="3">
              <s-date
                label="Fecha Vencimiento"
                v-model="props.item.VacDateEndDni"
              ></s-date>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form" md="3" lg="6" sm="6">
              <s-load
                @clear="clear()"
                @changeData="changeFile($event)"
                label="Adjuntar Carnet"
                hide-details
                v-model="file"
              ></s-load>
            </v-col>
          </v-row>
        </v-col>
      </template>

      <!-- <template v-slot:options>
        <div>
          <v-btn x-small color="info" @click="chargeMassive()">
            <i class="far fa-stop-circle"></i>Carga Masiva
          </v-btn>
        </div>
      </template> -->

      <template v-slot:options>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small elevation="0" @click="chargeMassive()">
              <v-icon style="font-size: 16px"
                >far fa-arrow-alt-circle-up</v-icon
              >
            </v-btn>
          </template>
          <span>Cargas Masivas</span></v-tooltip
        >
      </template>

    </s-crud>

    <div v-if="showDialogAdd">
      <v-dialog v-model="showDialogAdd" width="80%">
        <s-toolbar
          color="primary"
          dark
          label="Carga Masiva de Programación de Utilidades"
          @close="showDialogAdd = false"
          close
        ></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <s-import-excel
                  @loaded="loaded($event)"
                  :headers="headersMassive"
                ></s-import-excel>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>
<script>
import _sVaccine from "@/services/HumanResource/VaccineService";
import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
import SLoad from "../../../components/Utils/SLoad.vue";
import _sHelper from "@/services/HelperService";
export default {
  name: "RequirementService",
  components: { sSelectWorker, SLoad },
  props: {},

  data: () => ({
    showDialogAdd: false,
    filter: { TypeVaccine: 0, VacID: 0, TypeDose: 0 },
    file: [],
    DataWorker: {},
    config: {
      model: {
        VacID: "ID",
        TypeVaccine: "int",
        VacDate: "date",
        VacDateEndDni: "date",
        VacDateEmitionDni: "date",
      },
      service: _sVaccine,

      headers: [
        { text: "ID", value: "VacID", sortable: false, width: 10 },
        { text: "Documento", value: "PrsDocumentNumber", sortable: false },
        { text: "Trabajador", value: "WkrName", sortable: false },
        { text: "Edad", value: "WkrAge", sortable: false },
        { text: "Vacuna", value: "TypeVaccineText", sortable: false },
        { text: "Dosis", value: "TypeDoseText", sortable: false },
        { text: "Fecha", value: "VacDate", sortable: false },
        {
          text: "Fecha Emision Dni",
          value: "VacDateEmitionDni",
          sortable: false,
        },
        { text: "Fecha Venc. Dni", value: "VacDateEndDni", sortable: false },
      ],
    },

    headersMassive: [
      /* { text: "Tipo Planilla", value: "TypePayroll", column: "PLANILLA" }, */
      { text: "Trabajador", value: "TRABAJADOR", column: "TRABAJADOR" },
      { text: "Documento", value: "PrsDocumentNumber", column: "DNI" },
      { text: "Tipo Vacuna", value: "TypeVaccine", column: "TIPO DE VACUNA" },
      { text: "Dosis", value: "TypeDose", column: "DOSIS" },
      {
        text: "Fecha Vacuna",
        value: "VacDate",
        column: "FECHA VACUNA",
        date: true,
      },
      {
        text: "Fecha Emision Dni",
        value: "VacDateEmitionDni",
        column: "FECHA EMISION DNI",
        date: true,
      },
      {
        text: "Fecha Venc. Dni",
        value: "VacDateEndDni",
        column: "FECHA VENC DNI",
        date: true,
      },
      {
        text: "Observaciones",
        value: "VacDescription",
        column: "OBSERVACIONES",
      },
    ],
  }),

  methods: {

    initialize() {},
    save(item) {

      var fechaActual = this.$moment().format('YYYY-MM-DD');
      console.log('fecha actual', fechaActual);
      console.log('Fecha de emision', item.VacDateEmitionDni)
      console.log('Fecha caducidad', item.VacDateEndDni)

      if(item.VacDateEmitionDni == item.VacDateEndDni) {
        this.$fun.alert("La fecha de emisión y la fecha de vencimiento del DNI no pueden ser iguales","warning");
        return;
      }
      
      //console.log(this.DataWorker);
      item.vacAttach1 = this.file.name != null ? this.file.name : "";
      item.WkrID = this.DataWorker.WkrID;
      item.NtpID = this.DataWorker.NtpID;
      item.CtmID = this.DataWorker.CtmID;
      this.subirFiles();
      console.log("guardar", item);

      // console.log('fecha y su formato', item.VacDateEmitionDni);

      if (item.WkrID == null) {
        this.$fun.alert("Complete Nombre de Trabajador", "warning");
      } else {
        item.save();
      }
    },
    
    subirFiles() {
      if (this.file != null) {
        var fileName = this.file.name;
        var formData = new FormData();
        formData.append("file", this.file);
        _sHelper
          .uploadfile(1, this.$fun.getUserID(), formData, 4)
          .then((response) => {
            if (response.status == 200) {
              this.file = [];
              return;
            }
          });
      } else {
        this.$fun.alert("Sin datos que subir");
      }
    },
    dobleClick(item) {
      this.$emit("dblClick", item);
    },

    chargeMassive() {
      this.showDialogAdd = true;
    },

    loaded(table) {
      console.log("GUARDAR MASIVAAAAAAAAA", table);

      this.$fun.alert("Seguro de Cargar Datos?", "question").then((r) => {
        if (r.value) {
          table.map((item) => {
            item.UsrCreateID = this.$fun.getUserID();
          });

          console.log("GUARDADO", table);

          _sVaccine.saveMassive(table, this.$fun.getUserID()).then((r) => {
            this.$fun.alert("Agregados Correctamente", "success");
            this.showDialogAdd = false;
            this.$refs.crud.refresh();
          });
        }
      });
    },
  },

  watch: {
    files() {
      if (this.file) this.changeData(this.file);
    },
  },
  clear() {},
  created() {
    this.initialize();
  },
};
</script>
